import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const config = {
    tokenType: 'Bearer',
    storageTokenKeyName: 'token',
    storageRefreshTokenKeyName: 'refreshToken'
}

// Define a type for the slice state
interface AuthState {
    userData: any
}

// Define the initial state using that type
const initialState: AuthState = {
    userData: {}
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
   
    login: (state, action: PayloadAction<any>) => {
        
        state = {userData: action.payload.data,
            [config.storageTokenKeyName]: action.payload.data[config.storageTokenKeyName],
            [config.storageRefreshTokenKeyName]: action.payload.data[config.storageRefreshTokenKeyName]
        }

        localStorage.setItem('yepia-user-data', JSON.stringify(action.payload.data))
        localStorage.setItem('accessToken', action.payload.data[config.storageTokenKeyName])
        localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.data[config.storageRefreshTokenKeyName])
    },
    updateUser: (state, action: PayloadAction<any>) => {
        state = {userData: action.payload.data}
        localStorage.setItem('yepia-user-data', JSON.stringify(action.payload.data))
    },

    updateData: (state, action: PayloadAction<any>) => {
        state = {userData: action.payload.data}
        localStorage.setItem('yepia-user-data', JSON.stringify(action.payload.data))
    },

    logout: (state, action: PayloadAction<any>) => {
        state = {userData : {},
            [config.storageTokenKeyName]: null,
            [config.storageRefreshTokenKeyName]: null
        }
        // ** Remove user, accessToken & refreshToken from localStorage
        localStorage.removeItem('yepia-user-data')
        localStorage.removeItem('accessToken')
        localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    
  }
})

export const { login, logout, updateData,  updateUser} = authSlice.actions


export default authSlice.reducer
