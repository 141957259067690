import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
interface CartState {
  datas: Array<any>
}

// Define the initial state using that type
const initialState: CartState = {
  datas: []
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    getCart: (state) => {
      const result = localStorage.getItem('YEPIA-CART');
      if(result)
      state.datas = JSON.parse(result);
    },
    setCart: (state, action: PayloadAction<any>) => {
        const result = localStorage.getItem('YEPIA-CART');
        if(result) {
            const checkProductExist = state.datas?.filter((prod: any) => prod?.id === action.payload?.id)?.length > 0 ? true : false;
            
            if(checkProductExist) {
                for (let c of state.datas) {
                    if(c?.id === action.payload?.id) {
                        c.quantity =  action.payload?.quantity
                    }
                }
            }else {
                state.datas = [...JSON.parse(result), action.payload]
            }
            localStorage.setItem('YEPIA-CART', JSON.stringify(state.datas))
            return;
        }
        state.datas.push(action.payload)
        localStorage.setItem('YEPIA-CART', JSON.stringify(state.datas))
    },
    updateCart: (state, action: PayloadAction<Array<any>>) => {
        state.datas = action.payload
        localStorage.setItem('YEPIA-CART', JSON.stringify(state.datas))
    },
    deleteCart: (state) => {
      state.datas = []
      localStorage.removeItem('YEPIA-CART')
    },
  }
})

export const { setCart, getCart, updateCart, deleteCart } = cartSlice.actions


export default cartSlice.reducer
